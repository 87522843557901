/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBQ-pJcZ_yOjsIBM0PKt6ACUesaN6uOMxM",
  "appId": "1:513628014161:web:d8ed4acf86a9a1de7b5a70",
  "authDomain": "schooldog-i-coweta-ga.firebaseapp.com",
  "measurementId": "G-H39YP2GS0F",
  "messagingSenderId": "513628014161",
  "project": "schooldog-i-coweta-ga",
  "projectId": "schooldog-i-coweta-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-coweta-ga.appspot.com"
}
